<template>
  <div class="container mx-auto pb-10">
    <section class="CategoryPage__banner px-5 lg:px-0">
      <h1 class="mt-7 mb-2 md:mb-8 uppercase text-center text-lg md:text-2xl font-bold">
        {{ category.name }}
      </h1>
    </section>

    <OfferSlider
      v-if="options.supportOffers && offers && offers.length"
      v-slot="{ item }"
      class="container mx-auto md:h-auto relative mt-4 mb-11 md:mb-17"
      :items="offers"
      :desktop-slides="1"
    >
      <OfferCard
        :id="item.id"
        :image="item.image"
        :href="item.href || '/'"
        :title="item.title || ''"
        :description="item.description || ''"
        :homepage-offer="false"
        width="1200"
        height="515"
      />
    </OfferSlider>

    <div v-if="category" id="product-listing" class="my-5 md:my-10 flex items-center justify-start">
      <CategorySlider :category="category" @category="onSelectCategory" />
    </div>

    <ProductsListing
      v-model:facets="facets"
      :category-id="category.id?.toString()"
      :render-excluded-facets="['category_uid', 'category_id', 'is_gift_only']"
    />
  </div>
</template>

<script setup lang="ts">
import type { Category, MappedAggregation } from '@robustastudio/e-commerce/common';

const props = defineProps({
  category: {
    type: Object as PropType<Category>,
    default: null,
  },
});
const options = {
  supportOffers: true,
};

provide(CATEGORY_CONTEXT, ref(props.category));

// const { push: redirect } = useRouter();
const { offers } = useCategoryOffers(props.category.id || 0);

const { redirect } = useAppRouter();

const facets = ref<MappedAggregation[]>([]);

function onSelectCategory(url: string) {
  /**
   * set the selected category
   */
  redirect(`/${url}`);
}

useAppSeoMeta(
  computed(() => ({
    title: props.category.meta_title || props.category.name,
    description: props.category.meta_description,
    keywords: props.category.meta_keywords,
    image: props.category.image,
  })),
);
</script>

<i18n>
{
  "en": {
    "notFoundTitle": "We couldn't find products that match your criteria",
    "notFoundTip": "Try using different filters to find other products",
    "products": "Products",
    "subCategories": "Subcategories",
    "search": "Search Results",
    "subtitle": "Exquisite Essences"
  },
  "ar": {
    "notFoundTitle": "لم يتم العثور على منتجات متطابقة",
    "notFoundTip": "من فضلك قم باختيار مرشحات مختلفة للعثور على منتجات اخري",
    "products": "منتجات",
    "subCategories": "اقسام فرعيه",
    "search": "نتيجه البحث",
    "subtitle": "جوهر رائع"
  }
}
</i18n>
