<template>
  <div class="SliderNav">
    <div class="hidden md:flex items-center gap-x-2">
      <span class="text-primary-1-100 text-sm font-normal whitespace-nowrap">
        0{{ indexCounter + 1 }} / 0{{ itemsLength }}
      </span>
      <div class="flex">
        <button
          class="w-12 h-12 outline-none [ flex items-center justify-center ]"
          aria-label="previous"
          @click="prevSlide"
        >
          <svg-icon-chevron-left
            class="text-primary-1-100"
            :class="{
              'transform scale-x-reverse': $i18n.locale === 'ar',
            }"
            width="10"
            height="20"
          />
        </button>

        <button
          class="w-12 h-12 outline-none [ flex items-center justify-center ]"
          aria-label="next"
          @click="nextSlide"
        >
          <svg-icon-chevron-right
            class="text-primary-1-100"
            :class="{
              'transform scale-x-reverse': $i18n.locale === 'ar',
            }"
            width="10"
            height="20"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue';

const props = defineProps({
  itemThreshold: {
    type: Number,
    default: 1,
  },
});

const swiper = useSwiper();

const indexCounter = computed(() => {
  return swiper.value?.realIndex % props.itemThreshold;
});

const itemsLength = computed(() => {
  return swiper.value.slides?.length / props.itemThreshold;
});

function prevSlide() {
  swiper.value?.slidePrev();
}

function nextSlide() {
  swiper.value?.slideNext();
}
</script>

<style lang="postcss" scoped>
.SliderNav {
  @apply z-10;
  position: absolute;
  top: 110px;
}
</style>
