<template>
  <div
    class="OfferSlider overflow-hidden lg:overflow-visible relative"
    :class="{
      'OfferSlider--left-layout': orientation === 'left',
      'OfferSlider--right-layout': orientation === 'right',
    }"
  >
    <Swiper
      :dir="dir"
      :class="{ 'lg:-mt-40': orientation === 'right' }"
      :modules="modules"
      :slides-per-view="items.length === 1 ? 1 : 1.1"
      :centered-slides="centeredSlides"
      :center-insufficient-slides="true"
      :loop="loop"
      :space-between="20"
      :breakpoints="{
        '300': {
          slidesPerView: props.items.length === 1 ? 1 : 1.3,
          spaceBetween: 30,
          loop: loop,
          centeredSlides: centeredSlides,
          ...mobileOptions,
        },
        '768': {
          slidesPerView: items.length === 1 ? 1 : (props.desktopSlides as number | 'auto'),
          centerInsufficientSlides: false,
          centeredSlides: false,
          loop: loop,
          ...desktopOptions,
        },
      }"
      :autoplay="autoPlay ? { delay: autoPlay } : false"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      class="h-full"
    >
      <SwiperSlide
        v-for="(item, idx) in [...items, ...items, ...items]"
        :key="idx"
        class="flex flex-col items-center flex-shrink-0 lg:mt-auto"
      >
        <slot :item="{ ...item, idx }" />
      </SwiperSlide>
      <SwiperControls :current-index="currentIndex" @change="(index: number) => updateIndex(index)" />
      <OfferSliderNav v-if="arrows" :item-threshold="items.length" />
    </Swiper>

    <!-- If we need navigation buttons -->
  </div>
</template>
<script setup lang="ts">
import type { OffersContent } from '@robustastudio/e-commerce/common';
import type { OfferCardFragment } from '~/graphql/fragments';
import type { Unpacked } from '~/types/utils';
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
const modules = [Navigation, Autoplay];
type Offer = Array<Unpacked<OffersContent> & Partial<OfferCardFragment>>;

const props = defineProps({
  items: {
    type: Array as PropType<Offer>,
    required: true,
  },
  type: {
    type: String,
    default: 'primary',
  },
  autoPlay: {
    type: Number,
    default: 0,
  },
  desktopSlides: {
    type: [Number, String],
    default: 2,
  },
  desktopOptions: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
  mobileOptions: { type: Object, default: () => ({}) },
  arrows: {
    type: Boolean,
    default: false,
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 0,
  },
  orientation: {
    type: String,
    default: 'normal',
  },
  dir: {
    type: String,
    default: 'ltr',
  },
  centeredSlides: {
    type: Boolean,
    default: false,
  },
  loop: {
    type: Boolean,
    default: false,
  },
});

const currentIndex = ref(props.index);
const indexCounter = ref(props.index);

// function nextSlide() {
//   // limit the index to the length of the items -1

//   currentIndex.value = (currentIndex.value + 1) % props.items.length;
//   indexCounter.value = currentIndex.value;
// }

// function prevSlide() {
//   // limit minimum index to 0
//   currentIndex.value = (currentIndex.value - 1 + props.items.length) % props.items.length;
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function updateIndex(index: number) {
  indexCounter.value = props.items.length - 1 - (index % props.items.length);
}
</script>

<style lang="postcss" scoped>
.swiper-nav {
  @apply hidden lg:flex;
  width: 40px;
  height: 40px;
  &::after {
    content: unset;
  }
  svg {
    @apply text-black;
    height: 26px;
  }

  &.swiper-button-next {
    position: absolute;
    transform: scaleX(-1);
    right: 10px;
  }

  &.swiper-button-prev {
    position: absolute;
    left: 10px;
  }

  &.swiper-button-disabled {
    opacity: 0.3;

    svg {
      @apply text-gray-200;
    }
  }
}

.OfferSlider {
  &--right-layout {
    :deep(.swiper-slide) {
      .OfferCard {
        &__contnet {
          @apply items-end;
        }

        &__title {
          @apply text-left font-display;
        }
      }
    }
  }
}

/* Left and Right Orientation */
@screen lg {
  .OfferSlider {
    :deep(.swiper-slide-active) {
      margin-inline-end: 52px;
    }

    &--left-layout {
      :deep(.swiper-slide-next) {
        @apply items-center;
      }

      :deep(.SliderNav) {
        inset-inline-end: 0;
        direction: rtl;
      }
    }

    &--right-layout {
      padding: 80px 0 70px;

      :deep(.swiper-slide-next) {
        @apply items-center flex justify-center;
      }

      :deep(.swiper-slide-active) {
        .OfferCard {
          margin-inline-end: auto;
        }
      }

      :deep(.SliderNav) {
        inset-inline-start: 16px;
        direction: ltr;
      }
    }
  }
}

@media (max-width: 767px) {
  .OfferSlider {
    &--right-layout {
      :deep(.swiper-slide-prev) {
        @apply items-end;
      }
      :deep(.swiper-slide-active) {
        margin-left: 30px;
      }
    }

    .swiper-slide-next,
    .swiper-slide-prev {
      margin-block-start: auto;
    }
  }
}

/* purgecss start ignore */
[dir='ltr'] {
  .OfferSlider {
    &--right-layout {
      :deep(.swiper-slide) {
        .OfferCard {
          &__title {
            @apply font-display;
          }
        }
      }
    }
  }
}
/* purgecss end ignore */
</style>
