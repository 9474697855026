<template>
  <BulletsSlider
    v-if="categories && categories.length"
    v-slot="{ item }"
    :items="categories.filter(category => category.url_key)"
    :selected-item="categories"
    type="secondary"
    class="flex-1 pl-5 md:pl-0"
    :large-screen-attributes="{
      slidesPerView: 'auto',
    }"
    auto
  >
    <button
      class="px-5 py-4 border rounded-xl capitalize"
      :class="{
        'bg-primary-1-100 text-white font-bold border-primary-1-100': category.id === item.id,
        ' text-primary-1-100 border-primary-1-100': category.id !== item.id,
      }"
      :aria-label="$t('filterBy', { item: item.name.toLowerCase() })"
      @click="onSelectCategory(item)"
    >
      {{ item.name }}
    </button>
  </BulletsSlider>
</template>
<script setup lang="ts">
import type { Category } from '@robustastudio/e-commerce/common';
const { t: $t } = useI18n({
  useScope: 'local',
});
defineComponent({
  name: 'CategorySlider',
});

const props = defineProps({
  category: {
    type: Object as PropType<Category>,
    default: () => ({}),
  },
});

const { categories, category: selectedCategory } = useCategorySlider() as {
  categories: Ref<Category[]>;
  category: Ref<Category>;
};

/**
 * on mounted set the selected category component to the one injected
 */
onMounted(() => {
  /**
   * set the selected category
   */
  selectedCategory.value = props.category;
});

const emit = defineEmits<(event: 'category', url: string) => void>();

function onSelectCategory(category: Category) {
  if (!(category.url || category.url_key)) {
    return;
  }
  /**
   * set the selected category
   */

  emit('category', `${category.url || category.url_key}`);
}
</script>

<i18n>
  {
    "en": {
      "filterBy": "Select category filter {item}",
      
    },
    "ar": {
     "filterBy":"اختر الفئه {item}"
    }
  }
</i18n>
